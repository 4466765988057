
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator'
    import {
        Blockquote,
        Bold,
        BulletList,
        Code,
        HardBreak,
        Heading,
        History,
        HorizontalRule,
        Italic,
        Link,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        TiptapVuetify,
        Underline
    } from 'tiptap-vuetify'
    import ComponentTransKomSearch from '@/components/common/ComponentTransKomSearch.vue'
    import ComponentTranslateBox from '@/components/common/ComponentTranslateBox.vue'
    import ComponentSnippets from '@/components/messages/ComponentSnippets.vue'
    import { Template } from '@/models/data/template'
    import Notifications from '@/lib/notifications'
    import { getModule } from 'vuex-module-decorators'
    import Templates from '@/store/modules/templates'

    @Component({
        components: {
            TiptapVuetify,
            ComponentTransKomSearch,
            ComponentTranslateBox,
            ComponentSnippets
        },
    })
    export default class ComponentForm extends Vue {
        @Prop() template!: Template

        templatesModule = getModule(Templates, this.$store)

        range: Range | null = null
        selection: Selection | null = null

        saving = false

        dialog = {
            transkom: false,
            translate: false,
            snippets: false
        }

        extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [
                Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }
            ],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ]

        startTranskom (): void {
            this.dialog.transkom = !this.dialog.transkom
        }

        startDeepl (): void {
            this.dialog.translate = !this.dialog.translate
        }

        startSnippets (): void {
            this.dialog.snippets = !this.dialog.snippets
        }

        mountSelected (value: string): void {
            this.dialog.transkom = false
            this.dialog.translate = false
            this.dialog.snippets = false

            const sanitizedValue = value.replace('\n', '<br>')
            setTimeout(() => this.focusContent(sanitizedValue), 200)
        }

        focusContent (value: string | null = null): void {
            const field = document.querySelector('.ProseMirror') as HTMLElement
            const doc = field.ownerDocument

            if (value !== null) {
                this.range?.deleteContents()
                this.range?.insertNode(doc.createRange().createContextualFragment(value))

                this.range?.setStartBefore(field.children[field.children.length - 1])
                this.selection?.removeAllRanges()
                this.selection?.addRange(this.range as Range)
            }
        }

        saveRange (element: HTMLElement): void {
            const doc = element.ownerDocument
            const win = doc.defaultView

            if (win?.getSelection) {
                const sel = win.getSelection()
                if (sel?.getRangeAt && sel?.rangeCount) {
                    this.range = sel.getRangeAt(0)
                    this.selection = sel
                }
            }
        }

        async mounted (): Promise<void> {
            window.addEventListener('keydown', (event: KeyboardEvent) => {
                if (event.key === 'k' && event.ctrlKey) {
                    event.preventDefault()
                    this.startTranskom()
                }

                if (event.key === 'q' && event.ctrlKey) {
                    event.preventDefault()
                    this.startDeepl()
                }

                if (event.key === 's' && event.ctrlKey) {
                    event.preventDefault()
                    this.focusContent()
                }

                if (event.key === 'g' && event.ctrlKey) {
                    event.preventDefault()
                    this.startSnippets()
                }
            })

            await this.$nextTick()
            const field = document.querySelector('.ProseMirror') as HTMLElement
            field.onkeyup = () => this.saveRange(field)
            field.onmouseup = () => this.saveRange(field)

            field.focus()
        }

        async save (): Promise<void> {
            try {
                this.saving = true
                await this.templatesModule.save(this.template)
                this.$emit('close')
            } catch (e) {
                Notifications.error('Wystąpił problem w trakcie zapisywania.')
            } finally {
                Notifications.success('Zapisano pomyślnie.')
                this.saving = false
            }
        }
    }
