
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator'
    import ComponentForm from '@/components/templates/ComponentForm.vue'
    import Mode from '@/store/mode'
    import { Template } from '@/models/data/template'
    import { getModule } from 'vuex-module-decorators'
    import Templates from '@/store/modules/templates'
    import { PaginatedResponse } from '@/models/data/pagination/paginated-response'
    import Notifications from '@/lib/notifications'
    import { cloneDeep, isEqual } from 'lodash'

    @Component({
        components: {
            ComponentForm
        },
    })
    export default class PageTemplates extends Vue {
        templatesModule = getModule(Templates, this.$store)

        table = {
            page: 1,
            paginate: 20
        }

        loading = true
        removing: Template | null = null

        headers = [
            {
                text: 'Nazwa',
                value: 'name',
                width: '20%'
            },
            {
                text: 'Temat',
                value: 'subject',
                width: '25%'
            },
            {
                text: 'Treść',
                value: 'content',
                width: '45%'
            },
            {
                text: '',
                value: 'actions',
                width: 120
            }
        ]

        dialog = {
            enabled: false,
            template: new Template(),
            mode: Mode.CREATE
        }

        get templates (): PaginatedResponse<Template>  {
            return this.templatesModule.entries
        }

        stripTags (html: string): string {
            return html.replace(/(<([^>]+)>)/gi, '')
        }

        isEqual (a: Template, b: Template): boolean {
            return isEqual(a, b)
        }

        create (): void {
            this.dialog.enabled = true
            this.dialog.template = new Template()
            this.dialog.mode = Mode.CREATE
            this.templatesModule.setMode(Mode.CREATE)
        }

        edit (template: Template): void {
            this.dialog.enabled = true
            this.dialog.template = cloneDeep(template)
            this.dialog.mode = Mode.EDIT
            this.templatesModule.setMode(Mode.EDIT)
        }

        async remove (template: Template): Promise<void> {
            try {
                this.removing = template
                await this.templatesModule.remove(template)
                Notifications.success('Usunięto szablon.')
            } catch (e) {
                Notifications.error('Nie udało się usunąć szablonu.')
            }
            this.removing = null
        }

        async loadPage (): Promise<void> {
            this.loading = true
            try {
                await this.templatesModule.loadPage()
            } catch (e) {
                Notifications.error('Wystąpił problem przy ładowaniu szablonów wiadomości.')
                console.log(e)
            }
            this.loading = false
        }

        async mounted (): Promise<void> {
            await this.loadPage()
        }

        @Watch('table')
        async onTableChange (): Promise<void> {
            await this.loadPage()
        }
    }
